import './index.css';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import { useEffect, useState } from 'react';
import { http } from 'Utils/Http/Http';
import App from 'App';
import { LinearProgress } from '@mui/material';
import { AuthContext } from 'Contexts/AuthContext';
import GuardedRoute from 'Utils/Guards/GuardedRoute';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import SendResetLinkPage from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage';
import { LocalStorage } from 'Utils/LocalStorage';
const storage = new LocalStorage();


setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    required: 'required',
  },
});

function Entry() {
  const { t } = useTranslation();
  const [isFetchingUserData, setIsFetchingUserData] = useState(true);
  const [auth, setAuth] = useState({
    user: null,
    fetchUserProfile: async () => {
      try {
        const response = await http
          .get('/api/v1/customer_executive/profile');
        setAuth({ ...auth, user: response.data.data });
      } catch (error) {
        storage.remove('userToken');
        storage.remove('user');
        setAuth({ ...auth, user: null });
      }
    },
  });

  useEffect(() => {
    setIsFetchingUserData(true);
    auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
    // auth.refreshCsrf();
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {isFetchingUserData ? (
        <div className="h-screen flex justify-center items-center">
          <LinearProgress variant="query" className="w-72" />

        </div>) : (
        <Switch>
          <GuardedRoute
            guards={[GuestGuard]}
            exact
            path="/sign-in"
            component={SignInPage}
          />
          <Route exact path="/forgot-password" component={SendResetLinkPage} />
          <Route exact path="/forget-password/:emailId/:token" component={ResetPasswordPage} />
          <Route exact path="/email_verified/:emailId/:token" component={ResetPasswordPage} />
          {/* <Route exact path="/" render={() => {
            return (
              //   isFetchingUserData ?
              // <Redirect to="/si" /> :
              <Redirect to="/" />
            )
          }} /> */}
          <GuardedRoute guards={[AuthGuard]} path="/" component={App} />
          {/* <Route path="/admin" component={App} /> */}
        </Switch>
      )}
    </AuthContext.Provider>
  );
}

export default Entry;
