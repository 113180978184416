import { Grid } from '@mui/material'
import HomeCard from 'Components/Cards/HomeCard';
import React from 'react'
import { useTranslation } from 'react-i18next';
import listingCompany from 'Assets/homePage/listingCompany.png';
import marketFoundation from 'Assets/homePage/marketFoundation.png';
import unlicensed from 'Assets/homePage/unlicensed.png';
import others from 'Assets/homePage/others.png';
import guide from 'Assets/homePage/guide1.jpg';
import quesAns from 'Assets/homePage/quesAns.png';
import { makeStyles } from '@mui/styles';
import Header from './component/Header';


const useStyle = makeStyles((theme) => ({
    heading: {
        fontSize: "30px",
        color: "rgba(14, 109, 167, 1)",
        marginBottom: "30px"
    },
}));

const HomePage = () => {
    const { t } = useTranslation();
    const classes = useStyle();

    const complaintsArray = [
        {
            title: t("home.card.listedCompany"),
            image: listingCompany,
            path: t("pathUrl.listedCompanies")
        },
        {
            title: t("home.card.marketFoundation"),
            image: marketFoundation,
            path: t("pathUrl.marketFoundation")
        },
        {
            title: t("home.card.unlicensedBusiness"),
            image: unlicensed,
            path: t("pathUrl.unlicensedBusiness"),
            type: "unlicensedBusiness"
        },
        {
            title: t("home.card.other"),
            image: others,
            path: "other-companies"
        },
        {
            title: t("home.card.simplifiedProceduresGuide"),
            image: guide,
            path: "guide",
        },
        // {
        //     title: t("home.card.q&a"),
        //     image: quesAns,
        //     path: t("pathUrl.question")
        // },
    ]

    return (
        <div className='p-4'>
            <Header types={"home"}/>
            <div className='p-6'>
                <h2 className={classes.heading}>{t("home.complaintFiledAgainst")}</h2>
                <Grid container spacing={10}>
                    {complaintsArray?.map((item) => {
                        return (
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <HomeCard item={item} />
                            </Grid>
                        )
                    })
                    }
                </Grid>
            </div>
        </div>
    )
}

export default HomePage
