import React, { useState } from 'react';
import { Card, CircularProgress, Grid, Hidden } from '@mui/material';
import ResetPassword from './Components/ResetPassword';
import { http } from 'Utils/Http/Http';
import { makeStyles } from '@mui/styles';
import loginSideImage from 'Assets/loginSideImage.png';
import capitalMarketAuthorityLogo from 'Assets/capitalMarketAuthorityLogo.png';
import styles from './auth.module.css';


const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: "250px",
        padding: "40px",
        minWidth: "400px",
        maxWidth: "30%",
        maxHeight: "40%",
    },
    space: {
        backgroundColor: "rgba(244, 249, 253, 1)",
        minHeight: "100vh",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100vw",
        overflow: "hidden",
    },
    paper_left: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${loginSideImage})`,
    },
}));

export default function ResetPasswordPage(props) {
    const classes = useStyles();
    const { match } = props;
    const { params } = match;
    const [successfully, setSuccessfully] = useState(false)

    const resetPassword = (values) => {

        // http.put('/auth/reset-password', values);
    }

    return (
        <div className={classes.space}>
            {successfully ?
                <Card className={classes.card} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid container style={{ marginTop: "10px", display: 'flex', justifyContent: 'center' }} spacing={2}>
                        <Grid item xs={12}>
                            <h6 style={{ textAlign: 'center' }}>Password Changed Successfully.</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <h6 style={{ textAlign: 'center' }}><CircularProgress style={{ width: '20px', height: '20px' }} /> &nbsp; Redirecting to Login Page</h6>
                        </Grid>
                    </Grid>
                </Card>
                :
                <Grid container style={{ minHeight: "100vh" }}>
                    <Hidden only={["xs"]}>
                        <Grid item xs={0} md={6} className={styles.rightImage}>
                            <div style={{ background: "rgba(0,0,255,0.1)", height: "100%", width: "100%" }}></div>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={6} style={{ padding: "3% 8%" }}>
                        <Grid container style={{ height: "100%", marginBottom: "30px" }}>
                            <Grid item xs={12}>
                                <img src={capitalMarketAuthorityLogo} alt='logo' style={{ height: '50px', width: '286px' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <ResetPassword setSuccessfully={setSuccessfully} params={params} resetPassword={resetPassword} buttonClassName="flex justify-end mt-5" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </div>
    );
}
