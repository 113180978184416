import React from "react";
import { Card, Grid } from "@mui/material";

const CompanyNews = ({ companyNews, setOpenNews, setSelectedNews, classes }) => {
    return (
        <Grid container spacing={5} >
            {companyNews?.map((item) => {
                return (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card
                            className={classes.card}
                            onClick={() => {
                                setOpenNews(true);
                                setSelectedNews(item);
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    {/* <span style={{background:"rgba(65, 192, 173, 1)", width:"100px"}}></span> */}
                                    {item?.title}
                                    {/* <span style={{ color: "rgba(139, 187, 116, 1)" }}>(%0.14)0.05^</span> */}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    item?.description
                                                        ?.split("<br>")[0]
                                                        .slice(0, 30) + "...",
                                            }}
                                        ></div>
                                    </p>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default CompanyNews;
