import React, { useEffect, useState } from 'react'
import Header from 'Components/Header/Header';
import { useTranslation } from 'react-i18next';
import image1 from 'Assets/testImage1.png';
import icon11 from 'Assets/icon11.png';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import typicalProcessing from 'Assets/companies/typicalProcessing.png';
import CollapseCard from './components/CollapseCard';
import { http } from 'Utils/Http/Http';

import delistedCompany from 'Assets/companies/delistedCompany.png';
import pendingListing from 'Assets/companies/pendingListing.png';

import marketListing from 'Assets/companies/marketListing.png';
import companyListing from 'Assets/companies/companyListing.png';
import otherListing from 'Assets/companies/otherListing.png';
import { LocalStorage } from 'Utils/LocalStorage';
const storage = new LocalStorage();




const minHeigght = "calc(100vh - 270px)"

const useStyle = makeStyles((theme) => ({
    cardRight: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
    },
    cardLeft: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        // '&:hover': {
        //     color: 'white',
        //     backgroundColor: "rgba(14, 109, 167,1)"
        // }
    },
    titleLeft: {
        fontSize: "16px",
        padding: "4px 10px",
        color: "rgba(14, 109, 167, 1)",
        textAlign: "center"
    },
    titleRight: {
        fontSize: "16px",
        color: "rgba(14, 109, 167, 1)",
    },
    card: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        padding: "10px"
    },
}));

const TypicalProcessing = (props) => {

    const { t } = useTranslation();
    const classes = useStyle();
    const history = useHistory()
    const { qnaId } = useParams();
    const [companyDetaills, setCompanyDetaills] = useState()
    const [conapnyQnA, setConapnyQnA] = useState();
    const [answerDetails, setAnswerDetails] = useState();
    const [back, setBack] = useState(false);


    useEffect(() => {
        http.get(`/api/v1/customer_executive/company/${props?.match?.params?.id}`)
            .then((response) => {
                setCompanyDetaills(response.data.data);
            })
        http.get(`/api/v1/customer_executive/question_and_answer/${props?.match?.params?.qnaId}`)
            .then((response) => {
                setConapnyQnA(response?.data?.data);
            })
        http.get(`/api/v1/customer_executive/answer/${qnaId}`, {
            params: {
                // questionId: qnaId,
                // status: "completed",
                page: 1,
                limit: 10000,
                sort: "_id"
            }
        })
            .then((response) => {
                setAnswerDetails(response?.data?.data?.data);
            })
    }, [])
    const headerDetails = {
        title: companyDetaills?.title,
        image: companyDetaills?.status === "pending" ? pendingListing : companyDetaills?.status === "canceled" ? delistedCompany : companyDetaills?.category === "other" ? otherListing : companyDetaills?.category === "listed" ? companyListing : companyDetaills?.category === "market" ? marketListing : null,
        icon: companyDetaills?.logo,
    }

    const optionsArray = [
        {
            title: t("companyDetail.typicalProcessing"),
            image: typicalProcessing,
        },
    ]

    useEffect(() => {
        storage.set('open', 'true');
    }, [back]);



    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
            <Header item={headerDetails} setBack={setBack} back={back} />
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    {/* <h4 style={{ fontSize: "18px", fontWeight: "600", display: "flex" }}><div style={{ backgroundColor: "rgba(255, 0, 0, 1)", margin: "0 10px", width: "15px", height: "27px" }} />{conapnyQnA?.title}</h4> */}
                    <h4 style={{ fontSize: "18px", fontWeight: "600", display: "flex" }}><div style={{ backgroundColor: "rgba(255, 0, 0, 1)", margin: "0 10px", width: "15px", height: "27px" }} />تعطل تطبيق أو موقع الاهلى - التداول أون لاين</h4>
                </Grid>
                <Grid item sm={4} md={3} lg={3} xl={2}>
                    {optionsArray?.map((item) => {
                        return (
                            <Card className={classes.cardLeft}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={item?.image} alt='' style={{ width: "100%" }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.titleLeft}>{item?.title} <CheckCircleOutlineIcon style={{ color: "green", height: "15px" }} /></p>
                                    </Grid>
                                </Grid>
                            </Card>
                        )
                    })}
                </Grid>
                <Grid item sm={8} md={9} lg={9} xl={10}>
                    <Grid container spacing={4}>
                        {answerDetails?.map((item) => {
                            return (
                                <Grid item xs={12}>
                                    <CollapseCard data={item} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default TypicalProcessing

