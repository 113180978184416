import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import { Button, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import Rating from '@mui/material/Rating';

const useStyle = makeStyles((theme) => ({
    card: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        margin:"10px"
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    btn_more: {
        fontSize: "12px",
        color: "rgba(113, 205, 226, 1) !important",
        textTransform: "capitalize",
        '&:hover': {
            color: 'rgba(113, 205, 226, 1)',
        },
    }
}));

const CollapseCard = ({ data }) => {
    const classes = useStyle()
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <p style={{ display: "flex" }}>
                            <div style={{ backgroundColor: "rgba(113, 205, 226, 1)", margin: "0 10px", width: "34px", height: "27px" }} />
                            <div className='editor' dangerouslySetInnerHTML={{ __html: data?.answerDescription }}></div>
                        </p>
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {data?.closing?.map((item) => {
                    return (
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container style={{ borderRadius: "10px", border: "1px solid rgba(0,0,0,0.2)", padding: "10px" }} justifyContent="space-between" alignItems="center">
                                        <Grid item xs={12} md={9} lg={10}>
                                            <p>
                                                <div className='editor' dangerouslySetInnerHTML={{ __html: item?.closingDescriptionText }}></div>
                                            </p>
                                        </Grid >
                                        <Grid item xs={12} md={3} lg={2}>
                                            <Grid container>
                                                <Grid item style={{ borderRadius: "10px", border: "1px solid rgba(0,0,0,0.2)", padding: "10px", margin: "0 5px" }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <p style={{ textAlign: "center" }}>
                                                                <Rating value={item?.closingDescriptionRating} readOnly />
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    )
                })}
            </Collapse>
            <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
            <Grid container justifyContent="center">
                <Grid item>
                    <Button className={classes.btn_more} onClick={handleExpandClick}>{expanded ? "View Less" : "View More"}</Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CollapseCard
