import React from 'react'
import { Button, Card, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UTCtoLocal } from 'Constants/TimeZone/TimeZone';

const useStyle = makeStyles((theme) => ({
    btn_status_pending: {
        backgroundColor: "rgba(255, 243, 224, 1) !important",
        fontSize: "12px",
        cursor:"initial",
        color: "rgba(255, 157, 0, 1) !important",
        '&:hover': {
        cursor:"initial",
            color: 'rgba(255, 157, 0, 1) !important',
            backgroundColor: "rgba(255, 243, 224, 1) !important"
        }
    },
    btn_status_rejected: {
        backgroundColor: "rgba(255, 224, 224, 1) !important",
        fontSize: "12px",
        color: "rgba(255, 0, 0, 1) !important",
        '&:hover': {
            color: 'rgba(255, 0, 0, 1) !important',
            backgroundColor: "rgba(255, 224, 224, 1) !important"
        }
    },
    btn_status_approved: {
        backgroundColor: "rgba(221, 255, 240, 1) !important",
        fontSize: "12px",
        color: "rgba(34, 170, 111, 1) !important",
        '&:hover': {
            color: 'rgba(34, 170, 111, 1) !important',
            backgroundColor: "rgba(221, 255, 240, 1) !important"
        }
    }
}));

const QuestionAnswerCard = ({item}) => {
    const classes=useStyle()
    return (
        <>
        <Card style={{cursor:"pointer" }}>
            <Grid container direction="row" justifyContent="space-between" alignItems='center'>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{ padding: '20px' }}>
                { item?.title ? 
                    <h5>{item?.title}</h5>
                        :
                    <h5 className='editor' dangerouslySetInnerHTML={{ __html: item?.answerDescription }}></h5>
                }
                    <Typography style={{ color: '#a0a0a0', fontSize: '14px' }}>{UTCtoLocal(item?.createdAt, 'DD-MM-YYYY')}</Typography>
                </Grid>
                <Grid item style={{ padding: '20px' }}>
                    <Button className={item?.status==="completed"?classes.btn_status_approved:item?.status==="pending"?classes.btn_status_pending:classes.btn_status_rejected}>{item?.status}</Button>
                </Grid>
            </Grid>
        </Card>
        </>
    );
}
export default QuestionAnswerCard;




