import { Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';



const useStyle = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.background.paper,
        cursor:"pointer",
    },
    title:{
        fontSize: "16px",
        color: "rgba(14, 109, 167, 1)",
    },
    btn_back:{
        backgroundColor:"rgba(113, 205, 226, 1)",
        color:"white",
        zIndex:"1",
        '&:hover': {
            color: 'white',
            backgroundColor: 'rgba(38, 182, 214, 1)',
        }
    }
}));

const HomeCard = ({ item, height="auto",companyType }) => {
    const classes = useStyle();
    const history= useHistory()
    return (
        <Card className={classes.card} onClick={()=> item?.type  === "unlicensedBusiness" ? history.push({pathname: companyType ? `${companyType}/${item.path}` : `/${item.path}`, state: {category : "أعمال دون ترخيص"} }) : history.push(companyType ? `${companyType}/${item.path}` : `/${item.path}`)}>
            <Grid container>
                <Grid item xs={12}>
                    <img src={item?.image} alt='' style={{width:"100%", height:height}}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{padding:"15px 20px"}} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <p className={classes.title}>{item?.title}</p>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.btn_back}><ArrowBackIosNewIcon/></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default HomeCard
