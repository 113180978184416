import { Button, Card, Grid, InputAdornment, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import HeaderMain from 'Pages/HomePage/component/Header'




const Header = ({ item, setSearchString, search = false, back, setBack }) => {
  
    const useStyle = makeStyles((theme) => ({
        card: {
            backgroundImage: `url(${item?.image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition:"center",
            borderRadius: "20px",
            // backgroundColor:"rgba(123,12,21,1)",
            width: "100%",
            minHeight:"175px",
            marginBottom: "20px",
            position: "relative",
        },
        title: {
            fontSize: "35px",
            color: "rgba(255, 255, 255, 1)",
        },
        searchBar: {
            borderRadius: "50vh",
            height: "40px",
            width: "100%",
            padding: "20px"
        },
        btn_back: {
            color: "white",
            padding: "0",
            borderRadius: "50vh",
            '&:hover': {
                color: 'rgba(0,0,0,0.3)',
            }
        }
    }));
    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();

useState(()=>{
    if(back){
        setBack(true);
    }
},[back]);



    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <HeaderMain />
                </Grid>
            </Grid>
            <Grid container>
                <Card className={classes.card}>
                    <div style={{ backgroundColor: "rgba(0,0,0,0.2)", padding: "20px", height:"100%" }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "flex-end", marginBottom: "30px", minHeight: "50px" }}>
                                {item?.icon ?
                                    <>
                                        <Grid container alignItems="center">
                                            <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <img src={item?.icon} alt='' style={{ width: "50px", height: "50px" }} />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button className={classes.btn_back} onClick={() => history.goBack()}>{t("common.back")}<ArrowBackIosNewIcon /></Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <Button className={classes.btn_back} style={{ marginTop: "10px" }} onClick={() => history.goBack()}>{t("common.back")}<ArrowBackIosNewIcon /></Button>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container justifyContent="space-between" spacing={2} >
                                    <Grid item>
                                        <h4 className={classes.title}>{item?.title}</h4>
                                    </Grid>
                                    {search ?
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                style={{ background: "white" }}
                                                fullWidth
                                                onChange={(e) => setSearchString(e.target.value)}
                                                placeholder={t("common.search")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        </div>
    )
}

export default Header
