import { Btn, InputController, setProgress } from 'Components';
import Yup from 'Utils/Yup';
import { handleError, http } from 'Utils/Http/Http';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import logo from 'Assets/logo.svg';
import { makeStyles } from '@mui/styles';
import styles from '../auth.module.css';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';


import CryptoJS from "crypto-js";

let secretKey = "TjUM9OINvy1LH3GqA4Y9gW31SVGgQakQ2vjBktbk2c06mX8Uz0sv1dIHprzS"


const RESET_LINK_BTN = 'RESET_LINK_BTN';

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
});


const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: "28px",
        color: "rgba(14, 109, 167, 1)",
        marginTop: "15px",
        marginLeft: "20px",
    },
    text1: {
        fontSize: "15px",
        color: "grey",
        display: "flex",
        justifyContent: "center",
        marginLeft: "20px",
    },
}));

export default function SendResetLink({ buttonClassName, fullWidth, setLinkSent, sendResetLink }) {
    const { t } = useTranslation();
    const history = useHistory()
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const handleForgotPassword = async (values) => {
        setProgress(RESET_LINK_BTN, true);
        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(values), secretKey).toString();
        const data={payload:cipherText}
        try {
            await http.post(
                'api/v1/customer_executive/session/forget-password',
                { ...data },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                setLinkSent(true)
                setTimeout(() => {
                    history.push('/sign-in');
                }, 3000);
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
                setProgress(RESET_LINK_BTN, false);
            })

            setProgress(RESET_LINK_BTN, false);
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
        setProgress(RESET_LINK_BTN, false);
    };

    return (
        <form onSubmit={handleSubmit(handleForgotPassword)}>
            <div className="pt-2 pb-8 text-center">
                <h2><b>Forgot your Password</b></h2>
                <h3 style={{ marginBottom: "0px" }}>Enter your registered email address to get reset password link</h3>
            </div>
            <div className="space-y-4">
                <div>
                    <InputController
                        control={control}
                        name="email"
                        autoComplete='off'
                        label={t('password.emailAddress')}
                        placeholder={t('password.enterYourEmailAddress')}
                    />
                </div>
            </div>
            <div className={buttonClassName}>
                <Btn type="submit" name={RESET_LINK_BTN} fullWidth={fullWidth} className={styles.btn_primary}>
                    {t('password.sendResetLink')}
                </Btn>
            </div>
        </form>
    );
}
