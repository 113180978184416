import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Divider, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageCard from 'Pages/Companies/components/ImageCard';
import { useTranslation } from 'react-i18next';
import awarenessMaterial from 'Assets/companies/awarenessMaterial.png';
import committeeDecisions from 'Assets/companies/committeeDecisions.png';
import typicalProcessing from 'Assets/companies/typicalProcessing.png';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    maxWidth: "1000px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    btn_status_pending: {
        backgroundColor: "rgba(255, 243, 224, 1) !important",
        fontSize: "12px",
        padding:"10px",
        color: "rgba(255, 157, 0, 1) !important",
        '&:hover': {
            color: 'rgba(255, 157, 0, 1) !important',
            backgroundColor: "rgba(255, 243, 224, 1) !important"
        }
    },
    btn_status_rejected: {
        backgroundColor: "rgba(255, 224, 224, 1) !important",
        fontSize: "12px",
        padding:"10px",
        color: "rgba(255, 0, 0, 1) !important",
        '&:hover': {
            color: 'rgba(255, 0, 0, 1) !important',
            backgroundColor: "rgba(255, 224, 224, 1) !important"
        }
    },
    btn_status_approved: {
        backgroundColor: "rgba(221, 255, 240, 1) !important",
        fontSize: "12px",
        padding:"10px",
        color: "rgba(34, 170, 111, 1) !important",
        '&:hover': {
            color: 'rgba(34, 170, 111, 1) !important',
            backgroundColor: "rgba(221, 255, 240, 1) !important"
        }
    }
}));


const ModalDialog = ({ open, handleClose, item }) => {
    const classes = useStyle();
    const { t } = useTranslation();


    const optionsArray = [
        {
            title: t("companyDetail.committeeDecisions"),
            image: committeeDecisions,
            path: t("pathUrl.listedCompanies")
        },
        {
            title: t("companyDetail.awarenessMaterial"),
            image: awarenessMaterial,
            path: "#"
        },
        {
            title: t("companyDetail.typicalProcessing"),
            image: typicalProcessing,
            path: "#"
        },
    ]

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>{t("companyDetail.complaintDetails")}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Card className={classes.card} style={{ margin: '10px' }}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{ padding: '20px' }}>
                                        <h5 style={{ fontWeight: "600", marginBottom:"10px" }}>{item?.title}</h5>
                                        <p style={{ color: '#a0a0a0', fontSize: '14px' }}>
                                    <div className='editor' dangerouslySetInnerHTML={{ __html: item?.questionDescription ? item?.questionDescription : item?.answerDescription}}></div>
                                        </p>
                                    </Grid>
                                    <Grid item style={{ padding: '20px' }}>
                                        <div className={item?.status === "pending" ? classes.btn_status_pending : item?.status === "rejected" ? classes.btn_status_rejected : classes.btn_status_approved} style={{ color: "#ff9d00", fontSize: '11px' }}>{item?.status}</div>
                                    </Grid>
                                </Grid>
                            </Card >
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ModalDialog