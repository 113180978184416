import { Btn, InputController, setProgress } from 'Components';
// import { useQueryParams, Yup } from '@aseel/common-utils';
import Yup from 'Utils/Yup';
// import useQueryParams from 'Utils/UseQueryParams';
import { handleError, http } from 'Utils/Http/Http';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, InputAdornment, Link, SvgIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import logo from 'Assets/logo.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useHistory } from 'react-router-dom';
import styles from '../auth.module.css';
import { toast } from 'react-toastify';

import CryptoJS from "crypto-js";

let secretKey = "TjUM9OINvy1LH3GqA4Y9gW31SVGgQakQ2vjBktbk2c06mX8Uz0sv1dIHprzS"
const RESET_LINK_BTN = 'RESET_LINK_BTN';

const schema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Password and Confirm Password do not match.'),
});

export default function ResetPassword({ buttonClassName, fullWidth, resetPassword, setSuccessfully, params }) {
    const { t } = useTranslation();
    const history = useHistory()
    
    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });
    const [passwordType, setPasswordType] = useState('password')
    const [confirmType, setConfirmType] = useState('password')

    const togglePasswordType = (val) => {
        if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        } else if (val === 'confirm') {
            if (confirmType === 'password') {
                setConfirmType('text')
            } else if (confirmType === 'text') {
                setConfirmType('password')
            }
        }
    }

    const handleForgotPassword = async (values) => {
        const data = {
            email: params.emailId,
            resetPasswordToken: params.token,
            password: values.password
        }
        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        const dataClip={payload:cipherText}
        setProgress(RESET_LINK_BTN, true);
        try {
            await http.post(
                'api/v1/customer_executive/session/reset-password',
                { ...dataClip },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                setSuccessfully(true)
                setTimeout(() => {
                    history.push('/sign-in');
                }, 3000);
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
                setProgress(RESET_LINK_BTN, false);
            })

            setProgress(RESET_LINK_BTN, false);
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
        setProgress(RESET_LINK_BTN, false);
    };

    return (
        <form className="space-y-4" onSubmit={handleSubmit(handleForgotPassword)}>
            <Grid container style={{ marginTop: "10px" }} spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h2 style={{textAlign:"center"}}>{params.resetType==="email_verified"?"Set Password":"Reset Password"}</h2>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputController
                        control={control}
                        name="email"
                        type="email"
                        label="Email"
                        defaultValue={params.emailId}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputController
                        control={control}
                        type={passwordType}
                        name="password"
                        label="Password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                        {passwordType === 'password' ?
                                            <VisibilityOutlinedIcon />
                                            :
                                            <VisibilityOffOutlinedIcon />
                                        }
                                    </SvgIcon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputController
                        control={control}
                        type={confirmType}
                        name="confirmPassword"
                        label="Confirm Password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                                        {confirmType === 'password' ?
                                            <VisibilityOutlinedIcon />
                                            :
                                            <VisibilityOffOutlinedIcon />
                                        }
                                    </SvgIcon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '10px' }} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Btn type="submit" name={RESET_LINK_BTN} className={styles.btn_primary}>
                        {t('common.update')}
                    </Btn>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop:"10px" }}>
                    <Link component={RouterLink}
                        to="/sign-in"
                        style={{ color: 'rgba(14, 109, 167, 1)' }}
                    >Back to Sign In</Link>
                </Grid>

            </Grid>
        </form>
    );
}
