import React, { useEffect, useState } from 'react';
import Header from 'Components/Header/Header';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, IconButton, Card, Stepper, Step, StepLabel, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import image1 from 'Assets/testImage1.png';
import { Btn, DialogToast, EditorController, InputController, setProgress } from 'Components';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { handleError, http } from 'Utils/Http/Http';
import RatingController from 'Components/FormControls/Controllers/RatingController';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import delistedCompany from 'Assets/companies/delistedCompany.png';
import pendingListing from 'Assets/companies/pendingListing.png';
import marketListing from 'Assets/companies/marketListing.png';
import companyListing from 'Assets/companies/companyListing.png';
import otherListing from 'Assets/companies/otherListing.png';
import Step1 from 'Components/Steps/Step1';
import Step2 from 'Components/Steps/Step2';
import Step3 from 'Components/Steps/Step3';
import Step4 from 'Components/Steps/Step4';
import FileController from 'Components/FormControls/Controllers/FileController';

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const minHeigght = "calc(100vh - 270px)"

const useStyle = makeStyles((theme) => ({
    main: {
        display: "flex",
        justifyContent: "center",
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",
    },
    btn_delete: {
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255,0,0,0.5)",
        float: "left",
        '&:hover': {
            color: 'rgba(255,0,0,0.7)',
        }
    },
    btn_add: {
        backgroundColor: "transparent",
        fontSize: "14px",
        textTransform: "capitalize",
        color: "rgba(62, 199, 230, 1)",
        '&:hover': {
            color: 'rgba(19, 123, 146, 1)',
            backgroundColor: "transparent"
        }
    },
    btn_submit: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    },
    cardRight: {
        padding: "30px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
    },
    cardLeft: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
    },
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    navigation: {
        width: 110,
        fontSize: 12,
        [theme.breakpoints.down("xs")]: {
            fontSize: 10,
            width: 90
        }
    },
    prevBtn: {
        color: theme.palette.grey[700],
        background: theme.palette.common.white,
        boxShadow: theme.shadows[5]
    },
    instructions: {
        margin: "0 30px",
        marginTop: '30px'
    },
    paper_right_btn: {
        minHeight: "70px",
        marginTop: '1%',
        padding: "5%"
    },
}));

const closingss = yup.object({
    closingDescriptionRating: yup.number(),
    closingDescriptionText: yup.string().required("required")
});


const schema1 = yup.object().shape({
    questionDescription: yup.string().required("required"),
    title: yup.string().required("required"),
});

const items = yup.object().shape({
    answerDescription: yup.string().required("required"),
    closing: yup
        .array()
        .of(closingss)
});
const schema2 = yup.object().shape({
    items: yup
        .array()
        .of(items)
});
const schema3 = yup.object().shape({
    awarenessDescription: yup.string(),
});

const schema4 = yup.object().shape({
    category: yup.string()
});


const AddTopicCopy = () => {
    const { t } = useTranslation();
    const classes = useStyle();
    const history = useHistory()
    const [companyDetaills, setCompanyDetaills] = useState()
    const [activeStep, setActiveStep] = useState(0);
    const [navigationButton, setNavigationButton] = useState("next");
    const { companyId } = useParams();


    // const schema4 = yup.object().shape({
    //     category: yup.string().required()
    // });

    function getSteps() {
        return [t("addTopic.step1"), t("addTopic.step2"), t("addTopic.step3"), t("addTopic.step4")];
    }


    const form = useForm({
        resolver: yupResolver(activeStep === 0 ? schema1 : activeStep === 1 ? schema2 : activeStep === 2 ? schema3 : schema4),
    });
    const { control, handleSubmit, setError, reset, errors, watch } = form;


    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <Step1 control={control} />
            case 1:
                return <Step2 control={control} />
            case 2:
                return <Step3 control={control} form={form} errors={errors} />
            case 3:
                return <Step4 control={control} form={form} errors={errors} />
            default:
                return <Step1 />
        }
    }

    const steps = getSteps();


    const handleNext = (data) => {
        if (activeStep === 3) {
            if (watch('committeeDecisionsFiles')?.length && !watch('category')) {
                toast.error('Please choose Committee Decision');
            } else {
                handleAdd(data);
            }
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        http.get(`/api/v1/customer_executive/company/${companyId}`)
            .then((response) => {
                setCompanyDetaills(response.data.data);
            })
    }, [])

    const headerDetails = {
        title: companyDetaills?.title,
        image: companyDetaills?.status === "pending" ? pendingListing : companyDetaills?.status === "canceled" ? delistedCompany : companyDetaills?.category === "other" ? otherListing : companyDetaills?.category === "listed" ? companyListing : companyDetaills?.category === "market" ? marketListing : null,
        icon: companyDetaills?.logo,
    }

    const handleAdd = async (values) => {
        setProgress(CREATE_PAYOUT_BTN, true);

        let formDataAwareness = new FormData();

        for (var i = 0; i < values.awarenessMaterialFiles?.length; i++) {
            formDataAwareness.append("attachment", values.awarenessMaterialFiles[i]);
        }

        let formDataCommittee = new FormData();

        for (var i = 0; i < values.committeeDecisionsFiles?.length; i++) {
            formDataCommittee.append("attachment", values.committeeDecisionsFiles[i]);
        }

        let temp2 = []

        for (let k = 0; k < values.items.length; k++) {
            temp2[k] = {
                status: "pending",
                answerDescription: values.items[k]?.answerDescription,
                closing: values.items[k]?.closing
            };

        }


        const data = {
            title: values.title,
            questionDescription: values.questionDescription,
            companyId,
            items: temp2,
        }


        if (values?.category) {
            data["committee"] = {
                decisionType: values?.category,
                status: 'pending',
            }
        }
        if (values?.awarenessDescription) {
            data["awareness"] = {
                description: values?.awarenessDescription,
                status: 'pending',
            }
        }

        if (values.awarenessMaterialFiles?.length) {
            try {
                await http.post(`/api/v1/customer_executive/helpers/upload-file`, formDataAwareness)
                    .then(async (res) => {
                        data.awareness['attachment'] = res?.data?.data
                    });
            } catch (error) {
                DialogToast.error(t('toast.errors.failedToUploadFile'));
                setProgress(CREATE_PAYOUT_BTN, false);
                return;
            }
        }

        if (values.committeeDecisionsFiles?.length) {
            try {
                await http.post(`/api/v1/customer_executive/helpers/upload-file`, formDataCommittee)
                    .then(async (res) => {
                        data.committee['attachment'] = res?.data?.data
                    });
            } catch (error) {
                DialogToast.error(t('toast.errors.failedToUploadFile'));
                setProgress(CREATE_PAYOUT_BTN, false);
                return;
            }
        }

        try {
            await http.post(`/api/v1/customer_executive/question_and_answer`, data)
                .then(async (res) => {
                    history.goBack();
                    toast.success(t('toast.success.addedSuccessfully'));
                });
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }

        // if (values.awarenessMaterialFiles?.length) {
        // if (values.committeeDecisionsFiles?.length) {
        //     try {
        //         await http.post(`/api/v1/customer_executive/helpers/upload-image`, formDataAwareness)
        //             .then(async (res) => {
        //                 data.awareness['attachment'] = res?.data?.data

        //                 try {
        //                     await http.post(`/api/v1/customer_executive/helpers/upload-image`, formDataCommittee)
        //                         .then(async (res) => {
        //                             data.committee['attachment'] = res?.data?.data

        //                             try {
        //                                 await http.post(`/api/v1/customer_executive/question_and_answer`, data)
        //                                     .then(async (res) => {
        //                                         history.goBack();
        //                                         toast.success(t('toast.success.addedSuccessfully'));
        //                                     });
        //                                 // reset({});
        //                             } catch (error) {
        //                                 // debugger
        //                                 toast.error(error?.response?.data?.message);
        //                             }
        //                         });
        //                 } catch (error) {
        //                     handleError(error, { setError });
        //                 }
        //             });
        //     } catch (error) {
        //         handleError(error, { setError });
        //     }
        // } else {
        //     try {
        //         await http.post(`/api/v1/customer_executive/helpers/upload-image`, formDataAwareness)
        //             .then(async (res) => {
        //                 data.awareness['attachment'] = res?.data?.data

        //                 try {
        //                     await http.post(`/api/v1/customer_executive/question_and_answer`, data)
        //                         .then(async (res) => {
        //                             history.goBack();
        //                             toast.success(t('toast.success.addedSuccessfully'));
        //                         });
        //                 } catch (error) {
        //                     toast.error(error?.response?.data?.message);
        //                 }
        //             });
        //     } catch (error) {
        //         handleError(error, { setError });
        //     }
        // }
        // } else {
        //     toast.error("Select Awareness Material Files.")
        // }
        setProgress(CREATE_PAYOUT_BTN, false);
    };


    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
            <Header item={headerDetails} />
            {/* <div className={classes.main}> */}
            <Grid container direction="row" justifyContent="center" spacing={8}>
                <Grid item xs={3} md={3} lg={3} xl={2}>
                    <Card className={classes.cardRight} style={{ minHeight: minHeigght }}>
                        <Grid container justifyContent="center" spacing={10}>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Stepper className={classes.verticalStep} activeStep={activeStep} orientation="vertical">
                                    {steps.map((label, index) => (
                                        <Step className={classes.verticalStepLabel} key={label}>
                                            <StepLabel className={classes.verticalStepLabel}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={9} md={9} lg={9} xl={10}>
                    <Card className={classes.cardLeft} style={{ padding: "40", minHeight: minHeigght }}>
                        <form className={classes.form} onSubmit={handleSubmit(handleNext)}>
                            <Grid container justifyContent="center" spacing={3} style={{ overflow: "scroll", height: "calc(100vh - 356px)", minHeight: "200px", paddingBottom: "30px" }}>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                </Grid>
                            </Grid>
                            <Divider width="100%" style={{ margin: '10px 0' }} />
                            <Grid
                                container
                                justifyContent="space-between"
                                style={{ padding: "20px 30px" }}
                            // className={classes.paper_right_btn}
                            >
                                <Grid item>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        variant="contained"
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {activeStep < steps.length - 1 && (
                                        <Button
                                            style={{ float: 'left' }}
                                            variant="contained"
                                            // onClick={handleNext}
                                            type='submit'
                                        >
                                            Next
                                        </Button>
                                    )}
                                    {activeStep === steps.length - 1 && (
                                        <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>{t("common.submit")}</Btn>
                                        // <Button
                                        //     style={{ float: 'left' }}
                                        //     color="primary"
                                        //     type="submit"
                                        //     variant="contained"
                                        // >
                                        //     Submit
                                        // </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
            {/* </div> */}
        </div>
    )
}

export default AddTopicCopy
