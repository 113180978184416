import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Divider, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageCard from 'Pages/Companies/components/ImageCard';
import { useTranslation } from 'react-i18next';
import awarenessMaterial from 'Assets/companies/awarenessMaterial.png';
import committeeDecisions from 'Assets/companies/committeeDecisions.png';
import typicalProcessing from 'Assets/companies/typicalProcessing.png';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    maxWidth: "1000px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    tabSelected: {
        backgroundColor: "rgba(14, 109, 167, 1)",
        borderRadius: "6px",
        color: 'white',
        width: "100%",
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'white',
            backgroundColor: "rgba(14, 109, 167,1)"
        }
    },
    tabUnselected: {
        backgroundColor: "white",
        width: "100%",
        borderRadius: "6px",
        color: 'rgba(14, 109, 167,1)',
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'rgba(14, 109, 167,1)',
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    btn_add: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    }
}));


const NewsDetailsModal = ({ open, handleClose, data }) => {
    const classes = useStyle();
    const { t } = useTranslation();


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>{t("companyDetail.newsDetails")}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <Grid container spacing={5}>
                        <Grid item sm={12}>
                                <h3 style={{ marginBottom: "10px" }}>{data?.title}</h3>
                                <p style={{ marginBottom: "10px", color: "rgba(0,0,0,0.6)" }}>
                                    <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                                </p>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default NewsDetailsModal
