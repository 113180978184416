import React, { useEffect, useState } from 'react';
import Logo from 'Assets/capitalMarketAuthorityLogo.png';
import BtnBase from 'Components/Buttons/BtnBase';
import { useTranslation } from 'react-i18next';
import { AppBar, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalStorage } from "Utils/LocalStorage";
import LanguageIcon from '@mui/icons-material/Language';
import ProfileButton from './ProfileButton';
import PasswordChangeModal from './PasswordChangeModal';
import { Link, useHistory } from 'react-router-dom';
import { Btn } from 'Components';


const storage = new LocalStorage();

const useStyle = makeStyles((theme) => ({
    localeBtn: {
        backgroundColor: theme.palette.primary.light,
    },
}));


const Header = ({ types }) => {
    const styles = useStyle();
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(false)

    const handleLangChange = () => {
        storage.set('language', i18n.language === 'ar' ? 'en' : 'ar');
        i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
    };


    return (
        <AppBar elevation={0} position="relative" className="p-6" style={{ backgroundColor: "white" }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item >
                    <Link to="/"><img src={Logo} className="w-auto max-h-24" alt="CX Logo" style={{ height: "55px" }} /></Link>
                </Grid>
                <Grid item >
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item >
                            {/* <BtnBase onClick={handleLangChange} className={styles.localeBtn} fullWidth={false}>
                        {i18n.language === 'en' ? 'العربية' : 'English'}&nbsp;<LanguageIcon />
                    </BtnBase> */}
                            {window.location.pathname === '/' ?
                                <Button style={{ color:'rgba(14, 109, 167, 1)' }} fullWidth={false} onClick={() => history.push(t("pathUrl.question"))}>
                                    <p>{t("home.card.q&a")}</p>
                                </Button>
                                :
                                ""
                            }
                        </Grid>
                        <Grid item >
                            <ProfileButton handlePasswordModal={() => setOpen(true)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <PasswordChangeModal open={open} handleClose={() => setOpen(false)} />
        </AppBar>
    )
}

export default Header
