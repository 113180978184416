import { Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import { useHistory } from 'react-router-dom';



const useStyle = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        padding: "15px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
    },
    title: {
        fontSize: "16px",
        color: "rgba(14, 109, 167, 1)",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap"
    },
    btn_back: {
        backgroundColor: "rgba(113, 205, 226, 1)",
        color: "white",
        zIndex: "1",
        '&:hover': {
            color: 'white',
            backgroundColor: 'rgba(38, 182, 214, 1)',
        }
    }
}));

const CompanyListingCard = ({ item, type }) => {
    const classes = useStyle();
    const history = useHistory()
    return (
        <Card className={classes.card} onClick={() => history.push({ pathname:`/company-details/${item._id}`,state: {companyType : type === 'other' ? type : ""}})}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2} sm={4} md={4} lg={4}>
                    <img src={item?.logo} alt='' style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                </Grid>
                <Grid item xs={10} sm={8} md={8} lg={8}>
                    <p className={classes.title}>{item?.title}</p>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CompanyListingCard
