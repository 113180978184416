import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileController from 'Components/FormControls/Controllers/FileController';
import { RadioGroupController } from 'Components';
import ImageFileController from 'Components/FormControls/Controllers/ImageFileController';

const useStyle = makeStyles((theme) => ({
    main: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        maxWidth: "800px"
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",
    },
    btn_delete: {
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255,0,0,0.5)",
        float: "left",
        '&:hover': {
            color: 'rgba(255,0,0,0.7)',
        }
    },
    btn_add: {
        backgroundColor: "transparent",
        fontSize: "14px",
        textTransform: "capitalize",
        color: "rgba(62, 199, 230, 1)",
        cursor: "pointer",
        '&:hover': {
            color: 'rgba(19, 123, 146, 1)',
            backgroundColor: "transparent",
            cursor: "pointer",
        }
    },
    btn_submit: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    },
}));



const Step4 = (props) => {

    const { t } = useTranslation();
    const classes = useStyle();


    const {control, form, errors} = props;

    return (
        <>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <h3 style={{ textAlign: "center" }}>{t('addTopic.step4')}</h3>
            </Grid>
            <Grid item xs={12} >
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                    <RadioGroupController
                          control={control}
                          name="category"
                          row
                          options={[{ label: t('addTopic.compensate'), value: "Decision to Compensate" }, { label: t('addTopic.decisionRefuseCompensation'), value: "Decision to refuse compensation" }]}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}></Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <h3 className={classes.label} style={{ marginTop: '15px' }}>{t("addTopic.fileUpload")}</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                    <ImageFileController
                        name="committeeDecisionsFiles"
                        filesLimit={5}
                        acceptedFiles
                        form={form}
                        errors={errors}
                      />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Step4;