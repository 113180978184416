import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import image1 from 'Assets/guide/guideHeader.png';
import guideMap from 'Assets/guide/guideMap.png';
import { Grid, Card, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from 'Components/Header/Header';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';

const detail = 
{
    "subCategoryArray": [
        {
            "number": 1,
            "subCategory": "sub 1"
        },
        {
            "number": 2,
            "subCategory": "sub 2"
        },
        {
            "number": 3,
            "subCategory": "sub 3"
        },
        {
            "number": 4,
            "subCategory": "sub 4"
        },
        {
            "number": 5,
            "subCategory": "sub 5"
        },
        {
            "number": 6,
            "subCategory": "sub 6"
        }
    ],
        "attachment": [
            {
                "originalName": "ckeditor4-export.pdf",
                "src": guideMap
            }
        ]
}

const minHeigght = "calc(100vh - 270px)"




const Guide = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [value, setValue] = useState();
    const [details,setDetails] = useState({});

    const subCategories = useQuery( () =>

        http.post(`api/v1/article/category_subcategory`, { }).then(({ data }) => { return (data?.data, setDetails(data?.data)) }),

    );



    const useStyle = makeStyles((theme) => ({
    image: {
        backgroundImage: `url(${details?.attachment?.length ? details?.attachment[0]?.src : ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        height: "1000px",
        // borderRadius: "20px",
        backgroundColor: "rgba(255,255,255,1)",
        width: "100%",
        marginTop: "20px",
        // position: "relative",
    },
    link1: {
        display: "flex",
        justifyContent: "center",
        marginTop: "200px",
        marginRight: "250px",
        cursor: "pointer"
    },
    link1_sub: {
        height: "50px",
        width: "90px",
        // backgroundColor:"rgba(0,0,0,0.2)"
    },
    link2: {
        display: "flex",
        justifyContent: "center",
        marginTop: "446px",
        marginLeft: "140px",
        cursor: "pointer"
    },
    link2_sub: {
        height: "60px",
        width: "50px",
        // backgroundColor:"rgba(0,0,0,0.2)"
    },
    cardLeft: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
        padding: "0",
    },
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    tabSelected: {
        padding:'10px',
        backgroundColor: "rgba(14, 109, 167, 1)",
        borderRadius: "6px",
        color: 'white',
        width: "100%",
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'white',
            backgroundColor: "rgba(14, 109, 167,1)"
        }
    },
    tabUnselected: {
        padding:'10px',
        backgroundColor: "white",
        width: "100%",
        borderRadius: "6px",
        color: 'rgba(14, 109, 167,1)',
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'rgba(14, 109, 167,1)',
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
}));

const classes = useStyle();


    const headerDetails = {
        title: t("guide.SimplifiedProceduresGuide"),
        image: image1,
    }

    useEffect(()=>{
        http.get(`api/v1/customer_executive/progress_guide`, {

        }).then(({ data }) => { return (data, setDetails(data?.data)) });
   },[]);

    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
            <Header item={headerDetails} />
            <Grid container spacing={8}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Card className={classes.cardLeft} style={{ minHeight: minHeigght }}>
                        <Grid container spacing={3} justifyContent="center">
                            {details?.items?.map((item) => {
                                return (
                                    <Grid item xs={12} onClick={() => history.push({ pathname: '/unlicensed-business', state: { category: "دلـــيــــل الإجــــــراءات الــمــبـــســــط", subCategory: item?.subCategory, headerDetails: headerDetails } })}>
                                        <Grid container justifyContent="center" className={item?.subCategory === value ? classes.tabSelected : classes.tabUnselected}>
                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                        <Button onClick={() => setValue(item?.subCategory)}>{item?.subCategory}</Button>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <div >
                                           <p style={{float:'left'}}> {item?.number} </p>
                                            </div>
                                            </Grid>
                                            </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9} className={classes.image}>
                    <Grid container justifyContent="center">
                        <Grid item className={classes.link1}>
                            <div className={classes.link1_sub} onClick={() => history.push('unlicensed-business')}>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item className={classes.link2}>
                            <div className={classes.link2_sub} onClick={() => history.push('question')}>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Guide
