import React, { useEffect, useState } from 'react'
import { Button, Card, Divider, Grid, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import QuestionAnswerCard from 'Components/Cards/QuestionAnswerCard/QuestionAnswerCard';
import Header from 'Components/Header/Header';
import image1 from 'Assets/QuestionAnswer/questionAnswer.png';
import { http } from 'Utils/Http/Http';
import ModalDialog from './components/ModalDialog';
import { LocalStorage } from 'Utils/LocalStorage';
const storage = new LocalStorage();

const minHeigght = "calc(100vh - 270px)"



const useStyle = makeStyles((theme) => ({
    cardRight: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
    },
    cardLeft: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
        padding: "0",
    },
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    tabSelected: {
        backgroundColor: "rgba(14, 109, 167, 1)",
        borderRadius: "6px",
        color: 'white',
        width: "100%",
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'white',
            backgroundColor: "rgba(14, 109, 167,1)"
        }
    },
    tabUnselected: {
        backgroundColor: "white",
        width: "100%",
        borderRadius: "6px",
        color: 'rgba(14, 109, 167,1)',
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'rgba(14, 109, 167,1)',
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",

    },
    btn_add: {
        backgroundColor: "rgba(62, 199, 230, 1) !important",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1) !important",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1) !important',
            backgroundColor: "rgba(62, 199, 230, 1) !important"
        }
    }
}));



const QuestionAnswer = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('1');
    const [qpage, setQuesPage] = useState(1);
    const [apage, setAnsPage] = useState(1)
    const [count, setCount] = useState(5);
    const [totalCount, setTotalCount] = useState();
    const [searchString, setSearchString] = useState();
    const dataPerPage = 12;
    const [quesAnsListing, setQuesAnsListing] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [AnsListing,setAnsListing] = useState();

    useEffect(() => {
        if(value === '1'){
        http.get(`/api/v1/customer_executive/question_and_answer_by_user`, {
            params: {
                page: qpage,
                limit: dataPerPage,
                searchString
            }
        })
            .then((response) => {
                setQuesAnsListing(response?.data?.data?.data)
                setTotalCount(response.data.data.totalCount)
                setCount(Math.ceil(response.data.data.totalCount / dataPerPage));
            })
        }
        else if(value === '2'){
            http.get(`/api/v1/customer_executive/answer_by_user`, {
                params: {
                    page: apage,
                    limit: dataPerPage,
                    searchString,
                }
            })
                .then((response) => {
                    setAnsListing(response?.data?.data?.data)
                    setTotalCount(response.data.data.totalCount)
                    setCount(Math.ceil(response.data.data.totalCount / dataPerPage));
                })
        }
    }, [qpage, apage, searchString, value])


    const handleModalDialog = (item) => {
        setOpen(true);
        setSelectedItem(item)
    }
    const handleClose = () => {
        setOpen(false);
    }
    const item = {
        title: t("home.card.q&a"),
        image: image1,
    }

    const sideTabs = [
        {
            // title: t("companyDetail.LatestHottestNews"),
            title: "حالة",
            value: "1"
        },
        {
            // title: t("companyDetail.frequentComplaints"),
            title: "معالجة نموذجية",
            value: "2"
        },
    ]
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const handleQuesChangePage = (event, newPage) => {
        setQuesPage(newPage);
    };

    const handleAnsChangePage = (event, newPage) => {
        setAnsPage(newPage);
    };


    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
             <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Header item={item} setSearchString={setSearchString} search={true} />
            </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                    <Card className={classes.cardRight} style={{ minHeight: minHeigght }}>
                        <Grid container spacing={3}>
                            {sideTabs?.map((item) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Button className={item?.value === value ? classes.tabSelected : classes.tabUnselected} onClick={() => handleChange(item?.value)}>{item?.title}</Button>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8} md={9} lg={9} xl={10}>
                    <Card className={classes.cardLeft} style={{ padding: "0", minHeight: minHeigght }}>
                        {
                            value === "1" ?
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "15px 30px" }}>
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <h3 className={classes.title}>جميع الحالات المقدمة </h3>
                                                {/* <h3 className={classes.title}>{t("companyDetail.frequentComplaints")}</h3> */}
                                                <p style={{ fontSize: "14px" }}>14-12-2021</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: " 15px 30px" }}>
                                        <Grid container spacing={4} style={{ marginTop: "8px" }}>
                                            {
                                                quesAnsListing?.map((item) => {
                                                    return (
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} onClick={() => handleModalDialog(item)}>
                                                            <QuestionAnswerCard item={item} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div style={{ float: 'right', marginTop: "10px" }}>
                                                <Pagination
                                                    variant="outlined" shape="rounded"
                                                    count={count}
                                                    page={qpage}
                                                    onChange={handleQuesChangePage}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                value === "2" ?
                                    <Grid container>
                                        <Grid item xs={12} style={{ padding: "15px 30px" }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <h3 className={classes.title}>جميع المعالجات النموذجية </h3>
                                                    {/* <h3 className={classes.title}>{t("companyDetail.frequentComplaints")}</h3> */}
                                                    <p style={{ fontSize: "14px" }}>14-12-2021</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: " 15px 30px" }}>
                                            <Grid container spacing={4} style={{ marginTop: "8px" }}>
                                                {
                                                    AnsListing?.map((item) => {
                                                        return (
                                                            <Grid item xs={12}  sm={12} md={12} lg={12} onClick={() => handleModalDialog(item)}>
                                                                <QuestionAnswerCard item={item} />
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            <Grid item xs={12}  sm={12} md={12} lg={12} >
                                                <div style={{ float: 'right',  marginTop: "10px"  }}>
                                                    <Pagination
                                                        variant="outlined" shape="rounded"
                                                        count={count}
                                                        page={apage}
                                                        onChange={handleAnsChangePage}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : null
                        }
                    </Card >
                </Grid >
            </Grid >
            < ModalDialog open={open} handleClose={handleClose} item={selectedItem} />
        </div >
    );
}

export default QuestionAnswer;