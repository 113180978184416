import { Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'



const useStyle = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        padding: "15px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    },
    title: {
        fontSize: "16px",
        color: "black",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        marginBottom: "0"
    },
    text: {
        fontSize: "14px",
        color: "rgba(62, 199, 230, 1)",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        marginBottom: "0",
        height:"19px",
        overflow:"hidden"
    },
}));

const TemplatesCard = ({ item }) => {
    const classes = useStyle();
    return (
        <Card className={classes.card}>
            <Grid container>
                <Grid item xs={12}>
                    <p className={classes.title}>{item?.title}</p>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.text} dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                    {/* <p >{item?.description}</p> */}
                </Grid>
            </Grid>
        </Card>
    )
}

export default TemplatesCard
