import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { handleError } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from 'Components/Header/Header';
import image1 from 'Assets/testImage1.png';
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
    Btn,
    InputController,
    setProgress,
    RadioGroupController,
    ResourcesList,
    EditorController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { HookFormDefaultValue } from "Components/HookFormDefaultValue/HookFormDefaultValue";
import { toast } from "react-toastify";
import Rating from '@mui/material/Rating';
import { LocalStorage } from 'Utils/LocalStorage';

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";
const storage = new LocalStorage();

const schema = Yup.object().shape({
    questionDescription: Yup.string().required("required"),
    title: Yup.string().required("required"),

});

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        maxWidth: "800px"
    },
    tabSelected: {
        backgroundColor: "rgba(14, 109, 167, 1)",
        borderRadius: "6px",
        color: 'white',
        width: "100%",
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'white',
            backgroundColor: "rgba(14, 109, 167,1)"
        }
    },
    tabUnselected: {
        backgroundColor: "white",
        width: "100%",
        borderRadius: "6px",
        color: 'rgba(14, 109, 167,1)',
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'rgba(14, 109, 167,1)',
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
    btn_add: {
        backgroundColor: "transparent",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(62, 199, 230, 1)",
        '&:hover': {
            color: 'rgba(19, 123, 146, 1)',
            backgroundColor: "transparent"
        }
    },
    btn_submit: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    },
    label: {
        textTransform: 'capitalize',
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.6)",
    }
}))

function AddTopic() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { companyId } = useParams();
    
    const [companyDetaills, setCompanyDetaills] = useState()
    useEffect(() => {
        http.get(`/api/v1/customer_executive/company/${companyId}`)
            .then((response) => {
                setCompanyDetaills(response.data.data);
            })
    }, [companyId])

    const headerDetails = {
        title: companyDetaills?.title,
        image: image1,
        icon: companyDetaills?.logo,
    }
    const [answerArray, setAnswerArray] = React.useState([{ answerDescription: '', closingDescriptionText: '', closingDescriptionRating: 2 }]);
    const addAnswer = () => {
        let temp = answerArray;
        temp.push({ answerDescription: '', closingDescriptionText: '', closingDescriptionRating: 2 })
        setAnswerArray([...temp])
    }
    const editObj = (index, val, key) => {
        let temp = answerArray;
        temp[index][key] = val;
        setAnswerArray([...temp])
    }
    const form = useForm({
        resolver: yupResolver(schema),
    });

    const { control, handleSubmit, setError, reset, errors } = form;

    const handleFormSubmission = async (values) => {
        setProgress(CREATE_PAYOUT_BTN, true);
        values['items'] = answerArray;
        values['companyId'] = companyId;

        try {
            await http.post(`/api/v1/customer_executive/question_and_answer`, values)
                .then(async (res) => {
                    history.goBack();
                    toast.success(t('toast.success.addedSuccessfully'))
                });
            // reset({});
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_PAYOUT_BTN, false);
    };

    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
            <Header item={headerDetails} />
            <div className={classes.main}>
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(handleFormSubmission)}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h3 style={{ textAlign: "center" }}>{t("addTopic.addNewTopic")}</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <h3 className={classes.label}>{t("addTopic.newTopicTitle")}</h3>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <InputController
                                        size="small"
                                        control={control}
                                        name="title"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <h3 className={classes.label}>{t('addTopic.newTopicTitle')}</h3>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <EditorController
                                        control={control}
                                        name="questionDescription"
                                    />
                                </Grid>
                                <Divider />
                            </Grid>
                        </Grid>
                        {answerArray.map((val, index) => {
                            return (<>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <h3 className={classes.label}>{t('addTopic.typicalProcessing')}</h3>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <EditorController
                                                control={control}
                                                onChange={(event, editor) => { editObj(index, editor.getData(), 'answerDescription') }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <h3 className={classes.label}>{t('addTopic.addDescription')}</h3>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8} xl={8}>
                                            <EditorController
                                                control={control}
                                                onChange={(event, editor) => { editObj(index, editor.getData(), 'closingDescriptionText') }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} xl={4}>
                                            <Rating
                                                className="m-10"
                                                name="simple-controlled"
                                                value={answerArray[index]?.closingDescriptionRating}
                                                onChange={(event, newValue) => { editObj(index, newValue, 'closingDescriptionRating') }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>);
                        })}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={8} md={8} lg={8} xl={8} onClick={addAnswer} style={{ cursor: 'pointer' }}>
                                    + Answer
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="flex pt-3">
                        <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                            {t("common.submit")}
                        </Btn>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddTopic;
