import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import image1 from 'Assets/guide/guideHeader.png';
import { Card, Divider, Grid, Pagination, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TemplatesCard from './components/TemplatesCard';
import GuideModal from 'Components/Modal/GuideModal';
import { http } from 'Utils/Http/Http';
import Header from 'Components/Header/Header';
import { useLocation } from "react-router-dom";

const minHeigght = "calc(100vh - 270px)"

const useStyle = makeStyles((theme) => ({
    cardRight: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
    },
    cardLeft: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
        padding: "0",
    },
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    tabSelected: {
        backgroundColor: "rgba(14, 109, 167, 1)",
        borderRadius: "6px",
        color: 'white',
        width: "100%",
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'white',
            backgroundColor: "rgba(14, 109, 167,1)"
        }
    },
    tabUnselected: {
        backgroundColor: "white",
        width: "100%",
        borderRadius: "6px",
        color: 'rgba(14, 109, 167,1)',
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'rgba(14, 109, 167,1)',
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",

    },
    btn_add: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    }
}));

const UnlicensedBusiness = (props) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const location = useLocation();
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(10);
    const [totalCount, setTotalCount] = useState()
    const [category, setCategory] = useState(location?.state?.category);
    const [subCategory, setSubCategory] = useState(location?.state?.subCategory ? location?.state?.subCategory : "");
    const [subCategoryList,setSubCategoryList] = useState([]);
    const dataPerPage = 12
    const [articleListing, setArticleListing] = useState()
    const [searchString, setSearchString] = useState()
    const headerDetails = {
        title: t("unlicensedBusiness.unlicensedBusiness"),
        image: image1,
    }

   

    const referralTextTemplates = [
        {
            title: "نموذج طلب النظر فى موضوع الشكوى والإفادة",
            text: `${t("unlicensedBusiness.model")} 101`,
            id: `1`
        },
        {
            title: "نموذج بحث إمكانية التسوية",
            text: `${t("unlicensedBusiness.model")} 201`,
            id: `2`
        },
        {
            title: "نموذج اعادة بحث تسوية",
            text: `${t("unlicensedBusiness.model")} 301`,
            id: `3`
        },
        {
            title: "نموذج تزويد بحركة محفظة",
            text: `${t("unlicensedBusiness.model")} 401`,
            id: `4`
        },
    ]


    useEffect(() => {
        if(category){
            http.post(`api/v1/customer_executive/article/category_subcategory`, { 
                type: 2,
                category: category
                }).then(({ data }) => { return (data, setSubCategoryList(data?.data)) });
        }
        http.get(`/api/v1/customer_executive/article/`, {
            params: {
                page: page,
                limit: dataPerPage,
                searchString,
                category: category,
                subCategory: subCategory
            }
        })
            .then((response) => {
                setArticleListing(response?.data?.data?.data)
                setTotalCount(response.data.data.totalCount)
                setCount(Math.ceil(response.data.data.totalCount / dataPerPage));
            })
    }, [page, searchString, category, subCategory])


    const [open, setOpen] = useState(false)
    const [data, setData] = useState()


    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
            <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Header item={location?.state?.headerDetails?.title ? location?.state?.headerDetails : headerDetails} search={true} setSearchString={setSearchString} />
            </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card className={classes.cardLeft} style={{ padding: "0", minHeight: minHeigght }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "15px 30px" }}>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={8} lg={8} xl={8} style={{ padding: "15px 30px" }}>
                                        <h3 className={classes.title}>{subCategory ? subCategory : location?.state?.subCategory ? location?.state?.subCategory : "أعمال دون ترخيص"}</h3>
                                        <p style={{ fontSize: "14px" }}>14-12-2021</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} style={{ padding: "15px 30px" }}>
                                        {/* <TextField
                                            name="category"
                                            select
                                            label="Category"
                                            size={'small'}
                                            disabled
                                            value={location?.state?.category}
                                            style={{ minWidth: "40%", marginLeft: '1%' }}
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                            <MenuItem selected value={location?.state?.category}><em>{location?.state?.category}</em></MenuItem>
                                        </TextField> */}

                                        {/* <TextField
                                            name="subCategory"
                                            select
                                            label="Sub-Category"
                                            size={'small'}
                                            defaultValue={subCategory ? subCategory : location?.state?.subCategory}
                                            onChange={(e) => setSubCategory(e.target.value)}
                                            style={{ minWidth: "100%" }}
                                        >
                                            { subCategoryList.map((subCat) => {
                                               return  <MenuItem value={subCat}>{subCat}</MenuItem>
                                            })
                                        }
                                        </TextField> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: " 15px 30px" }}>
                                <Grid container spacing={4} style={{ marginTop: "8px" }}>
                                    {articleListing ? articleListing.map((item) => {
                                        return (
                                            <>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  onClick={() => { setOpen(true); setData(item) }}>
                                                    <TemplatesCard item={item} />
                                                </Grid>
                                            </>
                                        )
                                    })
                                        : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Pagination
                                    style={{ float: 'right', margin: "10px" }}
                                    variant="outlined" shape="rounded"
                                    count={count}
                                    page={page}
                                    onChange={(e) => setPage(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <GuideModal open={open} handleClose={() => setOpen(false)} data={data} />
        </div>
    )
}

export default UnlicensedBusiness;
