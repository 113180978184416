import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditorController, InputController } from 'Components';


const useStyle = makeStyles((theme) => ({
    main: {
        display: "flex",
        justifyContent: "center",
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",
    },
}));



const Step1 = (props) => {

    const { t } = useTranslation();
    const classes = useStyle();

    const { control } = props;


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h3 style={{ textAlign: "center" }}>{t("addTopic.addNewTopic")}</h3>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <h3 className={classes.label}>{t("addTopic.newTopicTitle")}</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <InputController
                            size="small"
                            control={control}
                            name="title"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <h3 className={classes.label}>{t('addTopic.summaryOfTopic')}</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <EditorController
                            control={control}
                            name="questionDescription"
                        />
                        {/* <InputController
                            size="small"
                            control={control}
                            name="questionDescription"
                        /> */}
                    </Grid>
                    <Divider />
                </Grid>
            </Grid>
        </Grid>
    );
}
export default Step1;