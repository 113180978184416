import React from 'react'
import { useTranslation } from 'react-i18next';
import { Card, Divider, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import FileDownload from 'Components/PdfDownload/FileDownload';


const style = {
    position: 'absolute',
    maxWidth:'800px',
    width:"100%",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        // minWidth:"200px"
    },
    title: {
        fontSize: "14px",
        color: "rgba(62, 199, 230, 1)",
        fontWeight: "500"
    },
    details: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    text: {
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.6)",
        maxHeight:"60vh",
        overflow:"scroll"
    },
    btn_add: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    }
}));


const GuideModal = ({ open, handleClose, data }) => {
    const { t } = useTranslation();
    const classes = useStyle();

  

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.details}>تفاصيل</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <Grid container spacing={8}>
                        <Grid item sm={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <h3 className={classes.title}>{data?.title}</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider style={{ margin: "10px 0" }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                            <div className={classes.text} dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                                                {/* <p className={classes.text}>{data?.description}</p> */}
                                            </Grid>
                                            {data?.attachment?.map((pdf) => {
                                                return (
                                                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                        <FileDownload file={pdf} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default GuideModal
