import { makeStyles } from '@mui/styles';
import { Divider, Grid, IconButton, Card, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Btn, EditorController, InputController, setProgress } from 'Components';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { handleError, http } from 'Utils/Http/Http';
import RatingController from 'Components/FormControls/Controllers/RatingController';

const useStyle = makeStyles((theme) => ({
    main: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        maxWidth: "800px"
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",
    },
    btn_delete: {
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255,0,0,0.5)",
        float: "left",
        '&:hover': {
            color: 'rgba(255,0,0,0.7)',
        }
    },
    btn_add: {
        backgroundColor: "transparent",
        fontSize: "14px",
        textTransform: "capitalize",
        color: "rgba(62, 199, 230, 1)",
        cursor: "pointer",
        '&:hover': {
            color: 'rgba(19, 123, 146, 1)',
            backgroundColor: "transparent",
            cursor: "pointer",
        }
    },
    btn_submit: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    },
}));




const Step2a = (props) => {

    const { t } = useTranslation();
    const classes = useStyle();

    const { control, parentIndex } = props;

    const { fields, append, remove } = useFieldArray({
        control,
        name: `items[${parentIndex}].closing`
    });

    if (fields?.length < 1) {
        append({
            closingDescriptionText: "",
            closingDescriptionRating: 2
        })
    }


    return (
        <>
            <Grid item xs={12}>
                {fields.map((data, index) => {
                    return (
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={8} md={8} lg={8} xl={8}>
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <h3 className={classes.label}>{t('addTopic.addDescription')}</h3>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            <EditorController
                                                control={control}
                                                name={`items[${parentIndex}].closing[${index}].closingDescriptionText`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12}>
                                            {fields?.length > 1 ?
                                                <IconButton className={classes.btn_delete} onClick={() => remove(index)}><DeleteOutlineIcon /></IconButton>
                                                : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                    <div style={{ margin: "30px" }}>
                                        <RatingController
                                            control={control}
                                            name={`items[${parentIndex}].closing[${index}].closingDescriptionRating`}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={8} md={8} lg={8} xl={8}
                        onClick={(e) => {
                            e.preventDefault();
                            append({
                                closingDescriptionText: "",
                                closingDescriptionRating: 2
                            });
                        }}
                        className={classes.btn_add}
                    >
                        + Closing Description
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Step2a;