import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NavLinks } from 'Constants/NavLinks';
// import UserPage from 'Pages/User/UserPage';
import QuestionAnswer from 'Pages/QuestionAnswer/QuestionAnswer';
import BasicLayout from 'Layouts/BasicLayout/Index';
import HomePage from 'Pages/HomePage/HomePage';
import { useTranslation } from 'react-i18next';
import ListedCompanies from 'Pages/Companies/ListedCompanies/ListedCompanies';
import DetailedView from 'Pages/Companies/DetailedView/DetailedView';
import PendingCompanies from 'Pages/Companies/PendingCompanies/PendingCompanies';
import DelistedCompanies from 'Pages/Companies/DelistedCompanies/DelistedCompanies';
import AddTopic from 'Pages/Companies/DetailedView/components/AddTopic/AddTopic';
import CommitteeDecisions from 'Pages/Companies/DetailedView/components/CommitteeDecisions/CommitteeDecisions';
import AwarenessMaterial from 'Pages/Companies/DetailedView/components/AwarenessMaterial/AwarenessMaterial';
import TypicalProcessing from 'Pages/Companies/DetailedView/components/TypicalProcessing/TypicalProcessing';
import UnlicensedBusiness from 'Pages/UnlicensedBusiness/UnlicensedBusiness';
import GuideModal from 'Components/Modal/GuideModal';
import MarketFoundation from 'Pages/Companies/MarketFoundation/MarketFoundation';
import OtherCompanies from 'Pages/Companies/Others/OtherCompanies';
import AddTopicCopy from 'Pages/Companies/DetailedView/components/AddTopic/AddTopicCopy';
import Guide from 'Pages/Guide/Guide';


export default function App() {
    let { path } = useRouteMatch();
    const { t } = useTranslation();

    return (
        <BasicLayout links={NavLinks}>
            <Switch>
                <Route exact path={`${path}question`} component={QuestionAnswer} />
                <Route exact path={`${path}`} component={HomePage} />
                <Route exact path={`${path}${t("pathUrl.marketFoundation")}`} component={MarketFoundation} />
                <Route exact path={`${path}${t("pathUrl.listedCompanies")}`} component={ListedCompanies} />
                <Route exact path={`${path}:companyType/${t("pathUrl.companiesPendingListing")}`} component={PendingCompanies} />
                <Route exact path={`${path}:companyType/${t("pathUrl.delistedCompanies")}`} component={DelistedCompanies} />
                <Route exact path={`${path}company-details/:id`} component={DetailedView} />
                {/* <Route exact path={`${path}company-details/:id/committee-decisions`} component={CommitteeDecisions} /> */}
                {/* <Route exact path={`${path}company-details/:id/awareness-material`} component={AwarenessMaterial} /> */}
                {/* <Route exact path={`${path}company-details/:id/:qnaId/typical-processing`} component={TypicalProcessing} /> */}
                <Route exact path={`${path}company-details/:companyId/add-topic`} component={AddTopicCopy} />
                <Route exact path={`${path}unlicensed-business`} component={UnlicensedBusiness} />
                <Route exact path={`${path}unlicensed-business/:id`} component={GuideModal} />
                <Route exact path={`${path}other-companies`} component={OtherCompanies} />
                <Route exact path={`${path}guide`} component={Guide} />
                
            </Switch>
        </BasicLayout>
    );
}
