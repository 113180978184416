import React from "react";
import { Card, Grid } from "@mui/material";
import { Empty } from "Components";

const CompanyAnnouncements = ({
    companyAnnouncements,
    companyAnnouncementsLoading,
    classes,
    onShowAnnouncement
}) => {
    const renderAnnouncements = () =>
        companyAnnouncements?.length > 0 ? (
            companyAnnouncements?.map((item) => {
                return (
                    <Grid onClick={() => onShowAnnouncement(item)} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card
                            className={classes.card}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    {item?.title}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <div>{item?.summary}</div>
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            padding: "5px 0 0 0",
                                            display: "inline-block",
                                            color: "#666",
                                            opacity: "0.9",
                                        }}
                                    >
                                        {item?.announcementDate}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                );
            })
        ) : (
            <Empty />
        );

    return (
        <Grid container spacing={5}>
            {companyAnnouncementsLoading ? (
                <div
                    style={{
                        width: "100%",
                        height: "500px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Loading...
                </div>
            ) : (
                renderAnnouncements()
            )}
        </Grid>
    );
};

export default CompanyAnnouncements;
