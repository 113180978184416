import { Grid, Pagination, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import HomeCard from 'Components/Cards/HomeCard';
import companyListingImage from 'Assets/companies/companyListing.png';
import delistedCompanyImage from 'Assets/companies/delistedCompany.png';
import pendingListingImage from 'Assets/companies/pendingListing.png';
import company1 from 'Assets/companies/company1.png';
import company2 from 'Assets/companies/company2.png';
import CompanyListingCard from 'Components/Cards/CompanyListingCard/CompanyListingCard';
import Header from 'Components/Header/Header';
import { http } from 'Utils/Http/Http';


const ListedCompanies = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(10);
    const [totalCount, setTotalCount] = useState()
    const [searchString, setSearchString] = useState();
    const dataPerPage = 12
    const [companyListing, setCompanyListing] = useState()
    const item = {
        title: t("common.listedCompanies"),
        image: companyListingImage,
    }

    const companyHeading = [
        {
            title: t("common.companiesPendingListing"),
            image: pendingListingImage,
            path: `${t("pathUrl.companiesPendingListing")}`
        },
        {
            title: t("common.delistedCompanies"),
            image: delistedCompanyImage,
            path: `${t("pathUrl.delistedCompanies")}`
        },
    ]


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    useEffect(() => {
        http.get(`/api/v1/customer_executive/company`, {
            params: {
                page: page,
                limit: dataPerPage,
                status: "active",
                category: 'listed',
                searchString
            }
        })
            .then((response) => {
                setCompanyListing(response?.data?.data?.data)
                setTotalCount(response.data.data.totalCount)
                setCount(Math.ceil(response.data.data.totalCount / dataPerPage));
            })
    }, [page, searchString])

    return (
        <div style={{ padding: "40px 4%", minHeight: '100vh' }}>
            <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header item={item} setSearchString={setSearchString} search={true} />
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "40px" }}>
                {companyHeading?.map((item) => {
                    return (
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <HomeCard item={item} height="100px" companyType={'listing'} />
                        </Grid>
                    )
                })
                }
            </Grid>
            <Grid container spacing={4}>
                {
                    companyListing ?
                        companyListing?.map((item) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <CompanyListingCard item={item} companyType={'/listed'} />
                                </Grid>
                            )
                        })
                        :
                        "No Listed Company Found"
                }
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ float: 'right', marginTop: '10px', bottom: '0%' }}>
                    <Pagination
                        variant="outlined" shape="rounded"
                        count={count}
                        page={page}
                        onChange={handleChangePage}
                    />
                </div>
            </Grid>
        </div>
    )
}

export default ListedCompanies
