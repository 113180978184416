import React, { useEffect, useState } from "react";
import Header from "Components/Header/Header";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import delistedCompany from "Assets/companies/delistedCompany.png";
import pendingListing from "Assets/companies/pendingListing.png";

import marketListing from "Assets/companies/marketListing.png";
import companyListing from "Assets/companies/companyListing.png";
import otherListing from "Assets/companies/otherListing.png";

import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ComplaintsDetailsModal from "./components/ComplaintsDetailsModal";
import CompanyNews from "./components/CompanyNews/CompanyNews";
import CompanyAnnouncements from "./components/CompanyAnnouncements/CompanyAnnouncements";
import { useHistory } from "react-router-dom";
import { http } from "Utils/Http/Http";
import NewsDetailsModal from "./components/NewsDetailsModal";
import { LocalStorage } from "Utils/LocalStorage";
import { useLocation } from "react-router-dom";
import { UTCtoLocal } from "Constants/TimeZone/TimeZone";
import { DatePicker } from "Components";

const storage = new LocalStorage();

const minHeigght = "calc(100vh - 270px)";

const useStyle = makeStyles((theme) => ({
  heading: {
    padding: "15px 30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  datePickerWrapper: {
    display: "flex",
    flexDirection: "row",
    backdrop: "red",
    gap: "10px",
  },
  datePicker: {
    display: "inline",
    padding: "10px",
    background: "red",
  },
  cardRight: {
    padding: "10px",
    boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    minHeight: minHeigght,
  },
  cardLeft: {
    boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    minHeight: minHeigght,
    padding: "0",
  },
  card: {
    padding: "10px",
    boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
  },
  tabSelected: {
    backgroundColor: "rgba(14, 109, 167, 1)",
    borderRadius: "6px",
    color: "white",
    width: "100%",
    textTransform: "capitalize",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: "white",
      backgroundColor: "rgba(14, 109, 167,1)",
    },
  },
  tabUnselected: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: "6px",
    color: "rgba(14, 109, 167,1)",
    textTransform: "capitalize",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: "rgba(14, 109, 167,1)",
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
  title: {
    fontSize: "20px",
    color: "rgba(0, 0, 0, 1)",
  },
  btn_add: {
    backgroundColor: "rgba(62, 199, 230, 1) !important",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 1) !important",
    "&:hover": {
      color: "rgba(255, 255, 255, 1) !important",
      backgroundColor: "rgba(62, 199, 230, 1) !important",
    },
  },
}));

const DetailedView = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const [value, setValue] = useState(
    location?.state?.companyType === "other" ? "2" : "1"
  );
  const [announcement, setAnnouncement] = useState(null);

  const [companyDetaills, setCompanyDetaills] = useState();

  const [open, setOpen] = useState("false");
  const [companyQnA, setCompanyQnA] = useState();
  const [selectedQnA, setSelectedQnA] = useState();

  const [openNews, setOpenNews] = useState(false);
  const [companyNews, setCompanyNews] = useState();
  const [companyAnnouncements, setCompanyAnnouncements] = useState([]);
  const [companyAnnouncementsLoading, setCompanyAnnouncementsLoading] =
    useState([]);

  const [selectedNews, setSelectedNews] = useState();

  const [searchString, setSearchString] = useState();

  const [startDate, setStartDate] = useState(
    moment()
      .tz(moment.tz.guess())
      .subtract(3, "months")
      .format("YYYY-MM-DDTHH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().tz(moment.tz.guess()).format("YYYY-MM-DDTHH:mm:ss")
  );

  const handleChange = (newValue) => {
    setValue(newValue);
    // setSearchStringQna()
    // setSearchStringNews()
  };
  useEffect(() => {
    http
      .get(`/api/v1/customer_executive/company/${props.match.params.id}`)
      .then((response) => {
        setCompanyDetaills(response.data.data);
      });
  }, []);

  useEffect(() => {
    if (companyDetaills?.code) {
      setCompanyAnnouncementsLoading(true);
      http
        .get(
          `/api/v1/announcements?startDate=${startDate}&endDate=${endDate}&code=${companyDetaills.code}`
        )
        .then(({ data }) => {
          setCompanyAnnouncementsLoading(false);
          setCompanyAnnouncements(data?.data);
        })
        .catch((e) => {
          setCompanyAnnouncementsLoading(false);
        });
    }
  }, [startDate, endDate, companyDetaills]);

  useEffect(() => {
    if (value === "1") {
      http
        .get(`/api/v1/customer_executive/news`, {
          params: {
            page: 1,
            limit: 10000,
            companyId: props.match.params.id,
            searchString: searchString,
          },
        })
        .then((response) => {
          setCompanyNews(response?.data?.data?.data);
        });
    } else if (value === "2") {
      http
        .get(`/api/v1/customer_executive/question_and_answer`, {
          params: {
            page: 1,
            limit: 10000,
            companyId: props.match.params.id,
            status: "completed",
            searchString: searchString,
          },
        })
        .then((response) => {
          setCompanyQnA(response?.data?.data?.data);
        });
    }
  }, [value, searchString]);

  const headerDetails = {
    title: companyDetaills?.title,
    image:
      companyDetaills?.status === "pending"
        ? pendingListing
        : companyDetaills?.status === "canceled"
        ? delistedCompany
        : companyDetaills?.category === "other"
        ? otherListing
        : companyDetaills?.category === "listed"
        ? companyListing
        : companyDetaills?.category === "market"
        ? marketListing
        : null,
    icon: companyDetaills?.logo,
  };

  const sideTabs = [
    {
      title: t("companyDetail.LatestHottestNews"),
      value: "1",
    },
    {
      title: t("companyDetail.frequentComplaints"),
      value: "2",
    },
  ];

  const handleRemoveLocal = () => {
    setOpen(false);
    storage.set("open", "false");
    storage.remove("back");
    storage.remove("data");
  };

  const handleShowAnnouncement = (item) => {
    setAnnouncement(item);
  };

  return (
    <>
      <Dialog
        onClose={() => setAnnouncement(null)}
        aria-labelledby="simple-dialog-title"
        open={!!announcement}
        maxWidth="md"
        scroll="paper"
      >
        {announcement ? (
          <>
            <DialogTitle id="simple-dialog-title">
              {announcement.summary}
            </DialogTitle>
            <DialogContent>
              <div
                dangerouslySetInnerHTML={{ __html: announcement.body }}
              ></div>
            </DialogContent>
          </>
        ) : null}
        <DialogActions>
          <Button onClick={() => setAnnouncement(null)} color="primary">
          {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ padding: "40px 4%", minHeight: "100vh" }}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Header
              item={headerDetails}
              setSearchString={setSearchString}
              search={true}
            />
          </Grid>
        </Grid>
        {/* <Header item={headerDetails} setSearchString={value === "1" ? setSearchStringNews : setSearchStringQna} search={true} /> */}
        <Grid container spacing={8}>
          <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Card
              className={classes.cardRight}
              style={{ minHeight: minHeigght }}
            >
              <Grid container spacing={3}>
                {location?.state?.companyType === "other" ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      className={classes.tabSelected}
                      onClick={() => handleChange("2")}
                    >
                      {t("companyDetail.frequentComplaints")}
                    </Button>
                  </Grid>
                ) : (
                  <>
                    {sideTabs?.map((item) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            className={
                              item?.value === value
                                ? classes.tabSelected
                                : classes.tabUnselected
                            }
                            onClick={() => handleChange(item?.value)}
                          >
                            {item?.title}
                          </Button>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={9} lg={9} xl={10}>
            <Card
              className={classes.cardLeft}
              style={{ padding: "0", minHeight: minHeigght }}
            >
              {value === "1" ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.heading}
                  >
                    <div>
                      <h3 className={classes.title}>
                        {t("companyDetail.latestNews")}
                      </h3>
                      <p style={{ fontSize: "14px" }}>
                        {UTCtoLocal(Date.now(), "DD-MM-YYYY")}
                      </p>
                    </div>
                    <div className={classes.datePickerWrapper}>
                      <div>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          disableFuture={true}
                          onChange={(value) => {
                            setEndDate(
                              moment(value)
                                .tz(moment.tz.guess())
                                .format("YYYY-MM-DDTHH:mm:ss")
                            );
                          }}
                        />
                      </div>
                      <div>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          disableFuture={true}
                          onChange={(value) => {
                            setStartDate(
                              moment(value)
                                .tz(moment.tz.guess())
                                .format("YYYY-MM-DDTHH:mm:ss")
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider
                      fullWidth
                      style={{
                        background: "rgba(0, 0, 0, 0.16)",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      padding: " 40px 30px",
                    }}
                  >
                    <CompanyNews
                      companyNews={companyNews}
                      setOpenNews={setOpenNews}
                      setSelectedNews={setSelectedNews}
                      classes={classes}
                    />

                    <CompanyAnnouncements
                      classes={classes}
                      companyAnnouncements={companyAnnouncements}
                      companyAnnouncementsLoading={companyAnnouncementsLoading}
                      onShowAnnouncement={handleShowAnnouncement}
                    />
                  </Grid>
                </Grid>
              ) : value === "2" ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ padding: "15px 30px" }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <h3 className={classes.title}>
                          {t("companyDetail.frequentAskedQuestions")}
                        </h3>
                        <p style={{ fontSize: "14px" }}>14-12-2021</p>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classes.btn_add}
                          onClick={() =>
                            history.push(
                              `/company-details/${props.match.params.id}/add-topic`
                            )
                          }
                        >
                          {t("companyDetail.addNewTopic")} <AddIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider
                      fullWidth
                      style={{
                        background: "rgba(0, 0, 0, 0.16)",
                      }}
                    />
                  </Grid>
                  {companyQnA?.map((item) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ padding: " 15px 30px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card
                              className={classes.card}
                              onClick={() => {
                                setOpen("true");
                                setSelectedQnA(item);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <p>
                                    <FiberManualRecordIcon
                                      style={{
                                        margin: "0 10px",
                                        color: "rgba(214, 42, 44, 1)",
                                      }}
                                    />
                                    {item?.title}
                                  </p>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}
            </Card>
          </Grid>
        </Grid>
        {storage.get("open") === "true" ? (
          <ComplaintsDetailsModal
            open={storage.get("open") === "true" ? true : false}
            handleClose={() => handleRemoveLocal()}
            data={storage.get("data")}
          />
        ) : (
          <ComplaintsDetailsModal
            open={open === "true" ? true : false}
            handleClose={() => setOpen("false")}
            data={selectedQnA}
          />
        )}
        <NewsDetailsModal
          open={openNews}
          handleClose={() => setOpenNews(false)}
          data={selectedNews}
        />
      </div>
    </>
  );
};

export default DetailedView;
