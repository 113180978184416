import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Collapse, Divider, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageCard from 'Pages/Companies/components/ImageCard';
import { useTranslation } from 'react-i18next';
import awarenessMaterial from 'Assets/companies/awarenessMaterial.png';
import committeeDecisions from 'Assets/companies/committeeDecisions.png';
import typicalProcessing from 'Assets/companies/typicalProcessing.png';
import { LocalStorage } from 'Utils/LocalStorage';
import CollapseCard from './TypicalProcessing/components/CollapseCard';
import FileDownload from 'Components/PdfDownload/FileDownload';
import { http } from 'Utils/Http/Http';
const storage = new LocalStorage();


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    maxWidth: "1500px",
    maxHeight: "95vh",
    // overflow:"scroll",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        margin:"10px"
    },
    titleRight: {
        fontSize: "16px",
        color: "rgba(14, 109, 167, 1)",
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    btn_add: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    },
    btn_more: {
        fontSize: "12px",
        color: "rgba(113, 205, 226, 1) !important",
        textTransform: "capitalize",
        '&:hover': {
            color: 'rgba(113, 205, 226, 1)',
        },
    }
}));


const ComplaintsDetailsModal = ({ open, handleClose, path, data }) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [value, setValue] = useState();
    const [expanded, setExpanded] = useState(false);
    const [answerDetails, setAnswerDetails] = useState();


    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const handleCloseModal = () => {
        handleClose()
        setValue()
        setExpanded(false)
    }
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const optionsArray = [
        {
            title: t("companyDetail.typicalProcessing"),
            image: typicalProcessing,
            path: `company-details/${data?.companyId}/${data?._id}/typical-processing`,
            show: true
        },
        {
            title: t("companyDetail.awarenessMaterial"),
            image: awarenessMaterial,
            path: `company-details/${data?.companyId}/awareness-material`,
            show: data?.awareness?.status === "completed"
        },
        {
            title: t("companyDetail.committeeDecisions"),
            image: committeeDecisions,
            path: `company-details/${data?.companyId}/committee-decisions`,
            show: data?.committee?.status === "completed"
        },
    ]

    useEffect(() => {
        if (data) {
            storage.set('data', data);
            
            http.get(`/api/v1/customer_executive/answer/${data?._id}`, {
                params: {
                    // questionId: data?._id,
                    // status: "completed",
                    page: 1,
                    limit: 10000,
                    sort: "_id"
                }
            })
                .then((response) => {
                    setAnswerDetails(response?.data?.data?.data);
                })
        }
    }, [data]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>{t("companyDetail.complaintDetails")}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseModal}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <Grid container>
                        <Grid item sm={6} md={7} lg={8} xl={9}
                            style={{ overflow: "scroll", maxHeight:"calc(95vh - 90px)", marginBottom:"20px" }}
                        >
                            <Card className={classes.card} >
                                <Grid cotainer>
                                    <Grid item xs={12} style={{ padding: "10px" }}>
                                        <h3 style={{ marginBottom: "10px" }}>{data?.title}</h3>
                                        {/* <h5 style={{ marginBottom: "10px", fontWeight: "600" }}>تكون الشكاوى عادة وفق الصيغ التا~لية:</h5> */}
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                            <p style={{ marginBottom: "10px", color: "rgba(0,0,0,0.6)" }}>
                                                <div className='editor' dangerouslySetInnerHTML={{ __html: data?.questionDescription }}></div>
                                            </p>
                                        </Collapse>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <Button className={classes.btn_more} onClick={handleExpandClick}>{expanded ? "View Less" : "View More"}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                            <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)", margin: "10px 0" }} />
                            <Grid container>
                                <Grid item xs={12}>
                                    <p style={{ color: "rgba(14, 109, 167, 1)", marginBottom: "10px" }}>{optionsArray[value]?.title}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    {value === 0 ?
                                        <Grid container spacing={8}>
                                            {answerDetails?.map((item) => {
                                                return (
                                                    <Grid item xs={12}>
                                                        <CollapseCard data={item} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : value === 1 ?
                                            <Grid container spacing={8}>
                                                <Grid item xs={12}>
                                                    <Card className={classes.card} style={{ padding: "10px"}}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <p className={classes.titleRight}>بالامكان الاستعانة بالمبدأ القضائى عند توعية الشاكى</p>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider style={{ margin: "10px 0" }} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <p style={{ fontSize: "16px", color: "rgba(150, 150, 150, 1)" }}>
                                                                    <div className='editor' dangerouslySetInnerHTML={{ __html: storage.get('data')?.awareness?.description }}></div>
                                                                </p>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Grid container spacing={3} style={{ marginTop: "10px" }}>
                                                                    {
                                                                        storage.get('data')?.awareness?.attachment?.map((file) => {
                                                                            return (
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <FileDownload file={file} />
                                                                                </Grid>
                                                                            );
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            : value === 2 ?
                                                <Grid container spacing={8}>
                                                    <Grid item xs={12}>
                                                        <Card className={classes.card} style={{ boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)", borderRadius: "8px", padding: "10px" }}>
                                                            <Grid container>
                                                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                                                    <p className={classes.titleRight}>{storage.get('data')?.committee?.decisionType==="Decision to Compensate"?t("addTopic.compensate"):t('addTopic.decisionRefuseCompensation')}</p>
                                                                </Grid>
                                                                {
                                                                    storage.get('data')?.committee?.attachment?.map((file) => {
                                                                        return (
                                                                            <Grid item xs={12} sm={6} >
                                                                                <FileDownload file={file} />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                                : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} md={5} lg={4} xl={3}
                            style={{ overflow: "scroll", maxHeight:"calc(95vh - 90px)", marginBottom:"20px" }}
                            >
                            <Card className={classes.card} style={{ padding: "10px" }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <h5 style={{ fontSize: "16px", color: "rgba(14, 109, 167, 1)" }}>{t("companyDetail.otherOptions")}</h5>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                                    </Grid>
                                    {optionsArray?.map((item, index) => {
                                        return (
                                            <Grid item xs={12}>
                                                {item?.show ?
                                                    <ImageCard item={item} index={index} value={value} handleChange={handleChange} />
                                                    : null}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ComplaintsDetailsModal
