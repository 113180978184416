import { makeStyles } from '@mui/styles';
import { Divider, Grid, IconButton, Card, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Btn, EditorController, InputController, setProgress } from 'Components';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { handleError, http } from 'Utils/Http/Http';
import RatingController from 'Components/FormControls/Controllers/RatingController';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import FileController from 'Components/FormControls/Controllers/FileController';
import ImageFileController from 'Components/FormControls/Controllers/ImageFileController';

const useStyle = makeStyles((theme) => ({
    main: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        maxWidth: "800px"
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",
    },
    btn_delete: {
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255,0,0,0.5)",
        float: "left",
        '&:hover': {
            color: 'rgba(255,0,0,0.7)',
        }
    },
    btn_add: {
        backgroundColor: "transparent",
        fontSize: "14px",
        textTransform: "capitalize",
        color: "rgba(62, 199, 230, 1)",
        cursor: "pointer",
        '&:hover': {
            color: 'rgba(19, 123, 146, 1)',
            backgroundColor: "transparent",
            cursor: "pointer",
        }
    },
    btn_submit: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    },
}));



const Step3 = (props) => {

    const { t } = useTranslation();
    const classes = useStyle();

    const {control, form, errors} = props;

    return (
        <>
        <Grid item xs={12} md={12} lg={12} xl={12}>
       <h3 style={{ textAlign: "center" }}>{t('addTopic.step3')}</h3>
        </Grid>
            <Grid item xs={12} >
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <h3 className={classes.label}>{t('addTopic.awareness')}</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <EditorController
                            control={control}
                            name="awarenessDescription"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}></Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <h3 className={classes.label} style={{ marginTop: '15px' }}>{t("addTopic.fileUpload")}</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <ImageFileController
                            name="awarenessMaterialFiles"
                            filesLimit={5}
                            acceptedFiles
                            form={form}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Step3;