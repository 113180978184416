import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'
import { useHistory } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LocalStorage } from 'Utils/LocalStorage';
const storage = new LocalStorage();


const useStyle = makeStyles((theme) => ({
    cardLeftActive: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        backgroundColor:"rgba(14, 109, 167, 1)",
        cursor:"pointer"
        // '&:hover': {
        //     color: 'white',
        //     backgroundColor: "rgba(14, 109, 167,1)"
        // }
    },
    cardLeftInactive: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        cursor:"pointer"
        // '&:hover': {
        //     color: 'white',
        //     backgroundColor: "rgba(14, 109, 167,1)"
        // }
    },
    title:{
        fontSize: "16px",
        padding: "4px 10px",
        textAlign:"center"
    },
}));

const ImageCard = ({ item,index,handleChange,value, height="auto" }) => {
    const classes = useStyle();

    return (
        <Card className={value===index? classes.cardLeftActive : classes.cardLeftInactive} onClick={() => handleChange(index)}>
            <Grid container>
                <Grid item xs={12}>
                    <img src={item?.image} alt='' style={{ width: "100%", height: height }} />
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.title} style={{color:value===index?"white":"rgba(14, 109, 167, 1)"}}>{item?.title} <CheckCircleOutlineIcon style={{color:value===index?"white":"green", height:"15px"}}/></p>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ImageCard
