import React, { useEffect, useState } from 'react'
import Header from 'Components/Header/Header';
import { useTranslation } from 'react-i18next';
import image1 from 'Assets/companies/pendingListing.png';
import { Button, Card, Divider, Grid, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import image2 from 'Assets/Row2.png';
import CompanyListingCard from 'Components/Cards/CompanyListingCard/CompanyListingCard';
import { http } from 'Utils/Http/Http';


const minHeigght = "calc(100vh - 270px)"

const useStyle = makeStyles((theme) => ({
    cardRight: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
    },
    cardLeft: {
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minHeight: minHeigght,
        padding: "0",
    },
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    tabSelected: {
        backgroundColor: "rgba(14, 109, 167, 1)",
        borderRadius: "6px",
        color: 'white',
        width: "100%",
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'white',
            backgroundColor: "rgba(14, 109, 167,1)"
        }
    },
    tabUnselected: {
        backgroundColor: "white",
        width: "100%",
        borderRadius: "6px",
        color: 'rgba(14, 109, 167,1)',
        textTransform: "capitalize",
        justifyContent: 'start',
        fontSize: "16px",
        '&:hover': {
            color: 'rgba(14, 109, 167,1)',
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
    title: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 1)",

    },
    btn_add: {
        backgroundColor: "rgba(62, 199, 230, 1)",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 1)",
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: "rgba(62, 199, 230, 1)"
        }
    }
}));

const PendingCompanies = (props) => {
    
    const { t } = useTranslation();
    const classes = useStyle();

    const [value, setValue] = useState('1');


    const [page, setPage] = useState(1)
    const [count, setCount] = useState(10);
    const [totalCount, setTotalCount] = useState()
    const dataPerPage=12
    const [companyListing, setCompanyListing] = useState()
    const [searchString, setSearchString] = useState()


    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const headerDetails = {
        title: props.match.params.companyType==="market"?t("market.companiesPendingListing"):t("common.companiesPendingListing"),
        image: image1,
    }

    useEffect(() => {
        http.get(`/api/v1/customer_executive/company`, {
            params: {
                page: page,
                limit: dataPerPage,
                status:"pending",
                category:props.match.params.companyType==="market"?"market":props.match.params.companyType==="listing"?"listed":"other",
                searchString
            }
        })
            .then((response) => {
                setCompanyListing(response?.data?.data?.data)
                setTotalCount(response.data.data.totalCount)
                setCount(Math.ceil(response.data.data.totalCount/dataPerPage));
            })
    }, [page,searchString])

    // const sideTabs = [
    //     {
    //         title: t("pendingCompanies.CanceledDueMergerAcquisition"),
    //         value: "1"
    //     },
    //     {
    //         title: t("pendingCompanies.closedCompanies"),
    //         value: "2"
    //     },
    // ]

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div style={{ padding: "40px 4%" }}>
            <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Header item={headerDetails} setSearchString={setSearchString} search={true}/>
            </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card className={classes.cardLeft} style={{ padding: "0", minHeight: minHeigght }}>
                        {
                            value === "1" ?
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "15px 30px" }}>
                                        {/* <h3 className={classes.title}>{t("pendingCompanies.CanceledDueMergerAcquisition")}</h3> */}
                                        <h3 className={classes.title}>{t("pendingCompanies.total")} {totalCount}</h3>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: " 15px 30px" }}>
                                        <Grid container spacing={4}>
                                            {companyListing?.map((item) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            <CompanyListingCard item={item} />
                                                        </Grid>
                                                    </>
                                                )
                                            })}
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Pagination
                                                    style={{ float: 'right' }}
                                                    variant="outlined" shape="rounded"
                                                    count={count}
                                                    page={page}
                                                    onChange={handleChangePage}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                value === "2" ?
                                    <Grid container>
                                        <Grid item xs={12} style={{ padding: "15px 30px" }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <h3 className={classes.title}>{t("pendingCompanies.closedCompanies")}</h3>
                                                    <p style={{ fontSize: "14px" }}>{t("pendingCompanies.total")} 03</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider fullWidth style={{ background: "rgba(0, 0, 0, 0.16)" }} />
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: " 15px 30px" }}>
                                            <Grid container spacing={4}>
                                                {companyListing.map((item) => {
                                                    return (
                                                        <Grid item xs={12} md={6} lg={6} xl={4}>
                                                            <CompanyListingCard item={item} />
                                                        </Grid>
                                                    )
                                                })}
                                                <Grid item xs={12}>
                                                    <Pagination
                                                        style={{ float: 'right' }}
                                                        variant="outlined" shape="rounded"
                                                        count={count}
                                                        page={page}
                                                        onChange={handleChangePage}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : null
                        }
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default PendingCompanies
