import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, Link, SvgIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import capitalMarketAuthorityLogo from 'Assets/capitalMarketAuthorityLogo.png';
import Btn from 'Components/Buttons/Btn';
// import useAuth from 'Hooks/UseAuth';
import { LocalStorage } from "Utils/LocalStorage";
import InputController from 'Components/FormControls/Controllers/InputController';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './auth.module.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { setProgress } from 'Utils/ProgressUtils/ProgressSvc';
import yup from 'Utils/Yup';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


import CryptoJS from "crypto-js";

let secretKey = "TjUM9OINvy1LH3GqA4Y9gW31SVGgQakQ2vjBktbk2c06mX8Uz0sv1dIHprzS"

const storage = new LocalStorage();

const SIGN_IN_BTN = 'SIGN_IN_BTN';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

export default function SignInPage() {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const history = useHistory();
    const [passwordType, setPasswordType] = useState('password')
    // const auth = useAuth();

    const { t } = useTranslation();

    // const { executeRecaptcha } = useGoogleReCaptcha();

    const handleLogin = async (values) => {
        setProgress(SIGN_IN_BTN, true);
        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(values), secretKey).toString();
        const data={payload:cipherText}
        try {
            await http.post(
                'api/v1/customer_executive/session/login',
                { ...data },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                storage.set("user", res?.data?.data);
                storage.set("userToken", res.data.data.loginData.token);
                setProgress(SIGN_IN_BTN, false);
                history.push('/')
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
                setProgress(SIGN_IN_BTN, false);
            })

            setProgress(SIGN_IN_BTN, false);
        } catch (error) {
            // handle errors
            setProgress(SIGN_IN_BTN, false);
        }
        setProgress(SIGN_IN_BTN, false);

    };

    const togglePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else if (passwordType === 'text') {
            setPasswordType('password')
        }
    }

    return (
        <Grid container style={{ minHeight: "100vh" }}>
            <Grid item xs={0} md={6} className={styles.rightImage}>
                <div style={{ background: "rgba(0,0,255,0.1)", height: "100%", width: "100%" }}></div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "3% 8%" }}>
                <Grid container style={{ height: "100%", marginBottom: "30px" }}>
                    <Grid item xs={12}>
                        <img src={capitalMarketAuthorityLogo} alt='logo' style={{ height: '50px', width: '286px' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <form className="space-y-4" onSubmit={handleSubmit(handleLogin)}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} style={{ marginBottom: "40px" }}>
                                    <h2>{t("signIn.interactiveGuide")}</h2>
                                    <h3 style={{ marginBottom: "0px" }}>{t("signIn.handleInvestorComplaints")}</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputController control={control} autoComplete='off' name="email" label={t('formLabels.email')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputController
                                        control={control}
                                        name="password"
                                        type={passwordType}
                                        autoComplete='off'
                                        label={t('formLabels.password')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SvgIcon fontSize="small" color="action" onClick={togglePasswordType} style={{ cursor: 'pointer' }}>
                                                        {passwordType === 'password' ?
                                                            <VisibilityOutlinedIcon />
                                                            :
                                                            <VisibilityOffOutlinedIcon />
                                                        }
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="flex items-center justify-end">
                                <Link component={RouterLink}
                                    to="/forgot-password"
                                    style={{ color: 'rgba(14, 109, 167, 1)' }}
                                >{t('signIn.forgetYourPassword')}</Link>
                            </div>
                            <Btn type="submit" name={SIGN_IN_BTN} className={styles.btn_primary}>
                                {t('signIn.signIn')}
                            </Btn>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
